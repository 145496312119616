import { InterfaceCollector } from '@interfaces/InterfaceCollector.ts';
import { useAppSelector } from '@store/appStore.ts';
import { selectCompanyCollectorParameters } from '@store/reducers/collectorReducer.ts';
import 'tailwindcss/tailwind.css';

export interface MachineInfoProps {
  collector: InterfaceCollector;
}

export default function MachineInfo({ collector }: MachineInfoProps) {
  const companyCollectorParameters = useAppSelector(selectCompanyCollectorParameters(collector.uuid)) ?? {};
  const sortedParameters = Object.values(companyCollectorParameters).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="w-full">
      <div className="grid grid-cols-6 gap-4">
        {sortedParameters.map((row, index) => (
          <div key={index} className="rounded-md border p-2">
            <div className="font-bold">{row.name}</div>
            <div className="text-tlOrange">
              {row.value}
              {row.unit}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
