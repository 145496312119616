import { routeOnFulfilled } from '@lib/axiosClientCached.ts';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { meLogOut, selectMe } from '@store/reducers/meReducer.ts';
import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

const SideNav: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    const resultAction = await dispatch(meLogOut());
    await routeOnFulfilled(navigate, '/', resultAction, meLogOut);
  };

  const userName = useAppSelector(selectMe);

  return (
    <>
      <nav className="-0 top-0 z-0 h-12 w-full bg-white shadow-md">
        <div className="float-right p-3">
          <div>Welcome, {userName ? `${userName.firstname} ${userName.lastname}` : 'Guest'}!</div>
          <button
            data-drawer-target="logo-sidebar"
            data-drawer-toggle="logo-sidebar"
            aria-controls="logo-sidebar"
            type="button"
            className="ms-3 mt-2 inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 sm:hidden">
            <span className="sr-only">Open sidebar</span>
            <svg
              className="h-6 w-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
          </button>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className="fixed left-0 top-0 z-40 h-screen w-64 -translate-x-full transition-transform sm:translate-x-0"
        aria-label="Sidebar">
        <div className="ul-outer h-full overflow-y-auto bg-white px-3 py-4 shadow-md">
          <a href="#" className="mb-5 flex items-center ps-2.5">
            <img
              src="https://tracklab.solar/wp-content/uploads/2022/07/WEB-Tracklab-Logo.png"
              className="me-3 h-11 sm:h-16"
              alt="Tracklab Logo"
            />
            <span className="self-center whitespace-nowrap text-xl font-semibold"> </span>
          </a>
          <ul className="side-nav-menu space-y-2 font-medium">
            <li>
              <NavLink
                to="/dashboard"
                className={({ isActive }) =>
                  isActive
                    ? 'active-item group flex items-center rounded-lg p-2 text-tlOrange hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700'
                    : 'group flex items-center rounded-lg p-2 text-tlOrange hover:bg-gray-100 dark:hover:bg-gray-700'
                }>
                <svg
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span className="ms-3">Dashboard</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/tcu-analytics"
                className="group flex items-center rounded-lg p-2 text-white hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink" // Corrected property name
                  fill="currentColor"
                  height="20px" // Adjusted height
                  width="20px" // Adjusted width
                  version="1.1"
                  id="Capa_1"
                  viewBox="0 0 463 463"
                  xmlSpace="preserve" // Corrected property name
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true">
                  <path d="M439.5,68h-416C10.542,68,0,78.542,0,91.5v160C0,264.458,10.542,275,23.5,275H208v49h-0.5c-8.547,0-15.5,6.953-15.5,15.5 v8.5h-32.5c-4.142,0-7.5,3.357-7.5,7.5v32c0,4.143,3.358,7.5,7.5,7.5h144c4.142,0,7.5-3.357,7.5-7.5v-32c0-4.143-3.358-7.5-7.5-7.5 H271v-8.5c0-8.547-6.953-15.5-15.5-15.5H255v-49h184.5c12.958,0,23.5-10.542,23.5-23.5v-160C463,78.542,452.458,68,439.5,68z M239,260v-33h97v33H239z M127,260v-33h97v33H127z M336,164h-97v-33h97V164z M351,131h97v33h-97V131z M239,116V83h97v33H239z M224,116h-97V83h97V116z M224,131v33h-97v-33H224z M112,164H15v-33h97V164z M15,179h97v33H15V179z M127,179h97v33h-97V179z M239,212v-33h97v33H239z M351,179h97v33h-97V179z M448,91.5V116h-97V83h88.5C444.187,83,448,86.813,448,91.5z M23.5,83H112v33H15 V91.5C15,86.813,18.813,83,23.5,83z M15,251.5V227h97v33H23.5C18.813,260,15,256.187,15,251.5z M296,380H167v-17h129V380z M256,339.5v8.5h-49v-8.5c0-0.275,0.224-0.5,0.5-0.5h48C255.776,339,256,339.225,256,339.5z M240,324h-17v-49h17V324z M439.5,260 H351v-33h97v24.5C448,256.187,444.187,260,439.5,260z" />
                </svg>
                <span className="ms-3 flex-1 whitespace-nowrap">TCU Analytics</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className="group flex items-center rounded-lg p-2 text-white hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                </svg>
                <span className="ms-3 flex-1 whitespace-nowrap">Board</span>
                <span className="menu-numerical-notification">3</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/"
                className="group flex items-center rounded-lg p-2 text-white hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20">
                  <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="ms-3 flex-1 whitespace-nowrap">Inbox</span>
                <span className="menu-numerical-notification">3</span>
              </NavLink>
            </li>
            <li>
              <a
                href="#"
                className="group flex items-center rounded-lg p-2 text-white hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                </svg>
                <span className="ms-3 flex-1 whitespace-nowrap">Users</span>
              </a>
            </li>
            <li>
              <NavLink
                to={'#'}
                onClick={logout}
                className="group flex items-center rounded-lg p-2 text-white hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700">
                <svg
                  className="h-5 w-5 text-tlOrange transition duration-75 group-hover:text-gray-900 dark:text-tlOrange dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="ms-3 flex-1 whitespace-nowrap">Sign Out</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default SideNav;
