import { InterfaceGraphPeriods } from '@components/CollectorGraphMeasurementWrapper/CollectorGraphMeasurementWrapper.tsx';
import { InterfaceCollectorMeasurementAverage } from '@interfaces/InterfaceCollectorMeasurementAverage.ts';
import { InterfaceCollectorMeasurements } from '@interfaces/InterfaceCollectorMeasurements.ts';
import { InterfaceMeasurementAverage } from '@interfaces/InterfaceMeasurementAverage.ts';
import { useAppSelector } from '@store/appStore.ts';
import { selectDataMeasurementType } from '@store/reducers/dataReducer.ts';
import { chartGetData } from '@utils/chartGetData.ts';
import { Chart, ChartOptions, registerables } from 'chart.js';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';

Chart.register(...registerables);
Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);

type TimeUnits = 'day' | 'hour';

export type CollectorGraphMeasurementsProps = {
  collectorMeasurements: InterfaceCollectorMeasurements | InterfaceCollectorMeasurementAverage;
  period: InterfaceGraphPeriods;
};

function isMeasurementAverage(point: any): point is InterfaceMeasurementAverage {
  return 'stddev' in point;
}

export default function CollectorGraphMeasurements({
  collectorMeasurements,
  period = 'week',
}: CollectorGraphMeasurementsProps) {
  const { unitName, unit } = collectorMeasurements;
  const data = useMemo(() => chartGetData(collectorMeasurements, period), [collectorMeasurements, period]);
  const [timeunit] = useState<TimeUnits>('day');
  const measurementTypes = useAppSelector(selectDataMeasurementType(collectorMeasurements.measurementTypeSlug));

  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: timeunit,
          displayFormats: {
            day: 'MMM D',
            hour: 'HH:mm',
          },
          tooltipFormat: 'YY/MM/DD HH:mm',
          round: 'minute',
        },
        ticks: {
          source: 'auto',
          autoSkip: true,
        },
        title: {
          display: true,
          text: 'DATE',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: `${unitName} (${unit})`,
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: measurementTypes.name ?? collectorMeasurements.measurementTypeSlug ?? '',
      },
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const rawData = context.raw as { x: number; y: number };
            const date = dayjs(rawData.x).format('YY/MM/DD HH:mm');
            const value = rawData.y;
            return `${date}: ${value} ${unit}`;
          },
          title: function () {
            return '';
          },
        },
      },
    },
  };

  if (collectorMeasurements.measurementTypeSlug === 'tcu-battery-current') {
    const formattedData = collectorMeasurements.data.map((point) => {
      if (isMeasurementAverage(point)) {
        return {
          x: dayjs(point.timestamp).toDate(),
          y: parseFloat(point.value.toString()),
          stddev: parseFloat(point.stddev.toString()),
        };
      } else {
        return {
          x: dayjs(point.timestamp).toDate(),
          y: parseFloat(point.value.toString()),
          stddev: 0,
        };
      }
    });

    const mainDataset = {
      label: 'Value',
      data: formattedData.map((point) => ({ x: point.x, y: point.y })),
      borderColor: '#f7941d',
      fill: false,
    };

    const upperBoundDataset = {
      label: 'Upper Bound',
      data: formattedData.map((point) => ({ x: point.x, y: point.y + point.stddev })),
      borderColor: 'rgba(0, 0, 255, 0.2)',
      fill: '+1',
    };

    const lowerBoundDataset = {
      label: 'Lower Bound',
      data: formattedData.map((point) => ({ x: point.x, y: point.y - point.stddev })),
      borderColor: 'rgba(0, 0, 255, 0.2)',
      fill: '-1',
    };

    data.datasets = [
      {
        ...mainDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y })),
      },
      {
        ...upperBoundDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y + point.stddev })),
      },
      {
        ...lowerBoundDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y - point.stddev })),
      },
    ];
  }

  if (collectorMeasurements.measurementTypeSlug === 'tcu-battery-voltage') {
    const formattedData = collectorMeasurements.data.map((point) => {
      if (isMeasurementAverage(point)) {
        return {
          x: dayjs(point.timestamp).toDate(),
          y: parseFloat(point.value.toString()),
          stddev: parseFloat(point.stddev.toString()),
        };
      } else {
        return {
          x: dayjs(point.timestamp).toDate(),
          y: parseFloat(point.value.toString()),
          stddev: 0,
        };
      }
    });

    const mainDataset = {
      label: 'Value',
      data: formattedData.map((point) => ({ x: point.x, y: point.y })),
      borderColor: '#f7941d',
      fill: false,
    };

    const upperBoundDataset = {
      label: 'Upper Bound',
      data: formattedData.map((point) => ({ x: point.x, y: point.y + point.stddev })),
      borderColor: 'rgba(0, 0, 255, 0.2)',
      fill: '+1',
    };

    const lowerBoundDataset = {
      label: 'Lower Bound',
      data: formattedData.map((point) => ({ x: point.x, y: point.y - point.stddev })),
      borderColor: 'rgba(0, 0, 255, 0.2)',
      fill: '-1',
    };

    data.datasets = [
      {
        ...mainDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y })),
      },
      {
        ...upperBoundDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y + point.stddev })),
      },
      {
        ...lowerBoundDataset,
        data: formattedData.map((point) => ({ x: point.x.getTime(), y: point.y - point.stddev })),
      },
    ];
  }

  if (collectorMeasurements.measurementTypeSlug === 'tcu-angle-current') {
    const formattedData = collectorMeasurements.data.map((point) => ({
      x: dayjs(point.timestamp).toDate().getTime(),
      y: parseFloat(point.value.toString()),
    }));

    const mainDataset = {
      label: 'Value',
      data: formattedData,
      borderColor: '#f7941d',
      fill: false,
      pointRadius: 0,
      pointHoverRadius: 15,
    };

    data.datasets = [
      {
        ...mainDataset,
      },
    ];
  }

  if (collectorMeasurements.measurementTypeSlug === 'tcu-angle-setpoint') {
    const formattedData = collectorMeasurements.data.map((point) => ({
      x: dayjs(point.timestamp).toDate().getTime(),
      y: parseFloat(point.value.toString()),
    }));

    const mainDataset = {
      label: 'Value',
      data: formattedData,
      borderColor: '#f7941d',
      fill: false,
      pointRadius: 0,
      pointHoverRadius: 15,
    };

    data.datasets = [
      {
        ...mainDataset,
      },
    ];
  }

  if (collectorMeasurements.measurementTypeSlug === 'tcu-battery-charge-status') {
    const formattedData = collectorMeasurements.data.map((point) => ({
      x: dayjs(point.timestamp).toDate().getTime(),
      y: parseFloat(point.value.toString()),
    }));

    const mainDataset = {
      label: 'Value',
      data: formattedData,
      borderColor: '#f7941d',
      backgroundColor: '#f7941d', // Set the fill color to orange
      fill: 'origin',
      pointRadius: 2,
      pointHoverRadius: 15,
    };

    data.datasets = [
      {
        ...mainDataset,
      },
    ];
  }

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
}
