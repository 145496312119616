import MachineInfo from '@components/collector/MachineInfo';
import CollectorGraphMeasurementWrapper from '@components/CollectorGraphMeasurementWrapper/CollectorGraphMeasurementWrapper.tsx';
import CollectorMeasurementSelect from '@components/CollectorMeasurementSelect/CollectorMeasurementSelect.tsx';
import CollectorSelect from '@components/CollectorSelect/CollectorSelect.tsx';
import FarmSelect from '@components/FarmSelect/FarmSelect.tsx';
import WeatherComponent from '@components/weather/WeatherComponent';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyAggregators,
  getCompanyAllCollectorAvailableMeasurements,
  getCompanyAllCollectorParams,
  getCompanyCollectorMeasurement,
  getCompanyCollectorMeasurementAverage,
  getCompanyCollectors,
  getCompanyFarms,
  selectCompanyCollectors,
} from '@store/reducers/collectorReducer.ts';
import { selectMe } from '@store/reducers/meReducer.ts';
import DownloadExcelButton from '@utils/DownloadExcelButton.tsx';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { URLSearchParamsInit } from 'react-router-dom/dist/dom';

Chart.register(...registerables);
Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);

const TCUAnalytics: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const me = useAppSelector(selectMe);
  const companyCollectors = useAppSelector(selectCompanyCollectors);

  const [companyFarmUuid, setCompanyFarmUuid] = useState<string>(searchParams.get('companyFarmUuid') ?? '');
  const [companyCollectorUuid, setCompanyCollectorUuid] = useState<string>(
    searchParams.get('companyCollectorUuid') ?? '',
  );
  const [dataMeasurementTypeSlug, setDataMeasurementTypeSlug] = useState<string>(
    searchParams.get('dataMeasurementTypeSlug') ?? '',
  );

  const startOfThisWeek = dayjs().startOf('week').format('YYYY-MM-DD');
  const endOfThisWeek = dayjs().endOf('week').format('YYYY-MM-DD');

  const [fromTimestamp] = useState<string>(searchParams.get('fromTimestamp') ?? startOfThisWeek);
  const [toTimestamp] = useState<string>(searchParams.get('toTimestamp') ?? endOfThisWeek);

  const [tcuError, setTcuError] = useState<{ date: string; value: number } | null>(null);
  const [tcuMode, setTcuMode] = useState<{ date: string; value: number } | null>(null);
  const [tcuRtcDate, setTcuRtcDate] = useState<{ date: string; value: number } | null>(null);

  const [isGraphLoaded, setIsGraphLoaded] = useState<boolean>(false);

  const handleGraphLoad = useCallback(() => {
    setIsGraphLoaded(true);
  }, []);

  useEffect(() => {
    if (me) {
      dispatch(getCompanyFarms());
      dispatch(getCompanyAggregators());
      dispatch(getCompanyCollectors());
      dispatch(getCompanyAllCollectorParams());
      dispatch(getCompanyAllCollectorAvailableMeasurements());
    }
  }, [me, dispatch]);

  useEffect(() => {
    const searchParms: URLSearchParamsInit = {};
    if (companyFarmUuid !== '') {
      searchParms.companyFarmUuid = companyFarmUuid;
    }
    if (companyCollectorUuid !== '') {
      searchParms.companyCollectorUuid = companyCollectorUuid;
    }
    if (dataMeasurementTypeSlug !== '') {
      searchParms.dataMeasurementTypeSlug = dataMeasurementTypeSlug;
    }
    if (fromTimestamp !== '') {
      searchParms.fromTimestamp = fromTimestamp;
    }
    if (toTimestamp !== '') {
      searchParms.toTimestamp = toTimestamp;
    }
    setSearchParams(searchParms);
  }, [setSearchParams, companyFarmUuid, companyCollectorUuid, dataMeasurementTypeSlug, fromTimestamp, toTimestamp]);

  useEffect(() => {
    const startOfThisMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endOfThisMonth = dayjs().endOf('month').format('YYYY-MM-DD');

    if (companyCollectorUuid) {
      const urlVarsReplaceMap = {
        '{collectorUuid}': companyCollectorUuid,
        '{measurementTypeSlug}': 'tcu-error',
        '{fromTimestamp}': startOfThisMonth,
        '{toTimestamp}': endOfThisMonth,
        '{exportType}': '',
      };

      dispatch(getCompanyCollectorMeasurementAverage({ urlVarsReplaceMap }))
        .then((response) => {
          // console.log('Response for tcu-error:', response);
          if (response.payload && response.payload.data && Array.isArray(response.payload.data)) {
            const latestEntry = response.payload.data[response.payload.data.length - 1];
            if (latestEntry) {
              const { timestamp, value } = latestEntry;
              setTcuError({ date: timestamp, value: Number(value) });
            }
          } else {
            console.warn('No data found for tcu-error');
          }
        })
        .catch((error) => {
          console.error('Error fetching tcu-error data:', error);
        });
    }
  }, [companyCollectorUuid, dispatch]);

  useEffect(() => {
    const startOfThisMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endOfThisMonth = dayjs().endOf('month').format('YYYY-MM-DD');

    if (companyCollectorUuid) {
      const urlVarsReplaceMap = {
        '{collectorUuid}': companyCollectorUuid,
        '{measurementTypeSlug}': 'tcu-mode',
        '{fromTimestamp}': startOfThisMonth,
        '{toTimestamp}': endOfThisMonth,
        '{exportType}': '',
      };

      dispatch(getCompanyCollectorMeasurementAverage({ urlVarsReplaceMap }))
        .then((response) => {
          // console.log('Response for tcu-mode:', response);
          if (response.payload && response.payload.data && Array.isArray(response.payload.data)) {
            const latestEntry = response.payload.data[response.payload.data.length - 1];
            if (latestEntry) {
              const { timestamp, value } = latestEntry;
              setTcuMode({ date: timestamp, value });
            }
          } else {
            console.warn('No data found for tcu-mode');
          }
        })
        .catch((error) => {
          console.error('Error fetching tcu-mode data:', error);
        });
    }
  }, [companyCollectorUuid, dispatch]);

  useEffect(() => {
    const startOfThisMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const endOfThisMonth = dayjs().endOf('month').format('YYYY-MM-DD');
    if (companyCollectorUuid) {
      const urlVarsReplaceMap = {
        '{collectorUuid}': companyCollectorUuid,
        '{measurementTypeSlug}': 'tcu-rtc-date',
        '{fromTimestamp}': startOfThisMonth,
        '{toTimestamp}': endOfThisMonth,
        '{exportType}': '',
      };
      dispatch(getCompanyCollectorMeasurement({ urlVarsReplaceMap }))
        .then((response) => {
          if (response.payload && response.payload.data && Array.isArray(response.payload.data)) {
            const latestEntry = response.payload.data[response.payload.data.length - 1];
            if (latestEntry) {
              const { timestamp, value } = latestEntry;

              const parsedValue = dayjs(value).valueOf();
              setTcuRtcDate({ date: timestamp, value: parsedValue });
            }
          } else {
            console.warn('No data found for tcu-rtc-date');
          }
        })
        .catch((error) => {
          console.error('Error fetching tcu-rtc-date data:', error);
        });
    }
  }, [companyCollectorUuid, dispatch]);

  return (
    <div className="site_level_analysis p-4 sm:ml-64">
      <div className="mb-28 rounded-lg border-2 border-dashed border-gray-200 p-4 dark:border-gray-700">
        <div className="items-left justify-lef mb-4 flex rounded bg-gray-50 px-3 py-3 dark:bg-gray-800">
          <p className="text-2xl text-tlBlue dark:text-gray-500">Site Level Analysis</p>
        </div>

        <div className="grid grid-cols-3 grid-rows-1 gap-4">
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-1">Select Site</h5>
            <FarmSelect setFarmUuid={setCompanyFarmUuid} initialValue={companyFarmUuid} />
          </div>
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-1">Select Collector </h5>
            <CollectorSelect
              farmUuid={companyFarmUuid}
              setCollectorUuid={setCompanyCollectorUuid}
              initialValue={companyCollectorUuid}
              disabled={companyFarmUuid === ''}
            />
          </div>
          <div className="basic-card items-left mb-4 flex flex-col">
            <h5 className="tl-sm-heading mr-4">Select Data</h5>
            <CollectorMeasurementSelect
              collectorUuid={companyCollectorUuid}
              setDataMeasurementTypeSlug={setDataMeasurementTypeSlug}
              initialValue={dataMeasurementTypeSlug}
              disabled={companyFarmUuid === ''}
            />
          </div>
        </div>

        {companyCollectorUuid !== '' && companyCollectors[companyCollectorUuid] !== undefined ? (
          <>
            <div className="basic-card mb-4 grid grid-rows-1 gap-4">
              <div className="col-span-4">
                <h5 className="tl-medium-heading">Selected Collector</h5>
                <h5 className="text-sm font-semibold text-tlOrange">{companyCollectors[companyCollectorUuid].name}</h5>
                <button className="graph-button mt-3 px-1 py-1 text-xs">Rename TCU Inactive</button>
              </div>
              <WeatherComponent collector={companyCollectors[companyCollectorUuid]} />
            </div>

            <div className="mb-4 grid grid-cols-4 grid-rows-1 gap-4">
              <div className="light-mode orange-card">
                <h5 className="tl-sm-heading-orange">TCU Error</h5>
                {tcuError ? (
                  <>
                    <p>{tcuError.value}</p>
                    <p>{tcuError.date.replace('T', ' ').replace(':00+00:00', '')}</p>
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
              <div className="light-mode orange-card">
                <h5 className="tl-sm-heading-orange">TCU Mode</h5>
                {tcuMode ? (
                  <>
                    <p>{tcuMode.value}</p>
                    <p>{tcuMode.date.replace('T', ' ').replace(':00+00:00', '')}</p>
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
              <div className="light-mode orange-card">
                <h5 className="tl-sm-heading-orange">TCU RTC Date</h5>
                {tcuRtcDate ? (
                  <>
                    <p>{tcuRtcDate.value}</p>
                    <p>{tcuRtcDate.date.replace('T', ' ').replace(':00+00:00', '')}</p>
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>

            <div className="basic-card mb-24">
              {dataMeasurementTypeSlug ? (
                <CollectorGraphMeasurementWrapper
                  collector={companyCollectors[companyCollectorUuid]}
                  dataMeasurementTypeSlug={dataMeasurementTypeSlug}
                  searchParams={searchParams}
                  fromTimestamp={fromTimestamp}
                  toTimestamp={toTimestamp}
                  onLoad={handleGraphLoad}
                />
              ) : (
                <></>
              )}

              {isGraphLoaded && (
                <div className="basic-card float-right mb-4">
                  <DownloadExcelButton
                    baseUrl="/api/v1"
                    companySlug="tracklab"
                    collectorUuid={companyCollectorUuid}
                    dataMeasurementTypeSlug={dataMeasurementTypeSlug}
                    fromTimestamp={fromTimestamp}
                    toTimestamp={toTimestamp}
                  />
                </div>
              )}
            </div>

            <div className="basic-card mb-4">
              <h5 className="tl-medium-heading mb-3">Collector Information</h5>
              <MachineInfo collector={companyCollectors[companyCollectorUuid]} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TCUAnalytics;
