import { InterfaceCollectorMeasurementAverage } from '@interfaces/InterfaceCollectorMeasurementAverage.ts';
import { InterfaceCollectorMeasurements } from '@interfaces/InterfaceCollectorMeasurements.ts';
import { ChartData } from 'chart.js';

export const chartGetData = (
  collectorMeasurements: InterfaceCollectorMeasurements | InterfaceCollectorMeasurementAverage,
  period: string,
  isPlainLineChart: boolean = false,
): ChartData<'line'> => {
  if (!collectorMeasurements || !collectorMeasurements.data) {
    console.error('Invalid collector measurements:', collectorMeasurements);
    return { labels: [], datasets: [] };
  }

  const dataset: number[] = [];
  const labels: string[] = [];
  const now = new Date();

  const includedHours = new Set<number>();

  collectorMeasurements.data.forEach((data) => {
    const dateObj = new Date(data.timestamp);
    const diffInMs = now.getTime() - dateObj.getTime();

    let includeDataPoint = false;

    switch (period) {
      case 'day':
        if (diffInMs <= 24 * 60 * 60 * 1000) {
          includeDataPoint = true;
        }
        break;
      case 'week':
        if (diffInMs <= 7 * 24 * 60 * 60 * 1000) {
          includeDataPoint = true;
        }
        break;
      case 'month':
        if (diffInMs <= 30 * 24 * 60 * 60 * 1000) {
          includeDataPoint = true;
        }
        break;
      default:
        includeDataPoint = true;
    }

    if (includeDataPoint) {
      if (isPlainLineChart) {
        const dateHour = dateObj.getHours();
        const dateDay = dateObj.getDate();
        const dateMonth = dateObj.getMonth();
        const dateYear = dateObj.getFullYear();
        const hourIdentifier = new Date(dateYear, dateMonth, dateDay, dateHour).getTime();

        if (!includedHours.has(hourIdentifier)) {
          includedHours.add(hourIdentifier);
          labels.push(data.timestamp);
          dataset.push(Number(data.value));
        }
      } else {
        labels.push(data.timestamp);
        dataset.push(Number(data.value));
      }
    }
  });

  return {
    labels,
    datasets: [
      {
        label: `${collectorMeasurements.measurementTypeSlug} (${period})`,
        data: dataset,
        fill: !isPlainLineChart,
        backgroundColor: 'rgba(247, 148, 29,0.6)',
        borderColor: 'rgba(10, 34, 59,1)',
        borderWidth: 0.5,
        pointRadius: isPlainLineChart ? 0 : undefined,
        pointHoverRadius: isPlainLineChart ? 0 : undefined,
      },
    ],
  };
};
