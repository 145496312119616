import { Bar } from 'react-chartjs-2';

const DemoCombinedCollectorGraph = () => {
  const data = {
    labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'],
    datasets: [
      {
        label: 'All Sites Generation Capacity Demo',
        data: [2000, 4000, 6000, 8000, 10000, 8000, 6000],
        backgroundColor: 'rgba(247, 148, 29,0.6)',
        borderColor: 'rgba(10, 34, 59,1)',
        borderWidth: 0.5,
        barPercentage: 0.8,
        categoryPercentage: 1.0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    scales: {
      y: {
        title: {
          display: true,
          text: 'KWH',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1000,
          max: 10000,
        },
      },
    },
  };

  return (
    <div className="py-6" style={{ width: '100%', height: '100%' }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default DemoCombinedCollectorGraph;
