import batteriesIcon from '@assets/images/batteries.svg';
import solarFarmIcon from '@assets/images/solar_farm_icon.svg';
import sunIcon from '@assets/images/sun.svg';
import unitsIcon from '@assets/images/units.svg';
import DemoCombinedCollectorGraph from '@components/CollectorGraphMeasurements/DemoCombinedCollectorGraph';
import DemoCombinedCollectorGraphPerformance from '@components/CollectorGraphMeasurements/DemoCombinedCollectorGraphPerformance';
import { checkIfActionFulfilled } from '@lib/axiosClientCached.ts';
import { AppRoutes } from '@router/AppRoutes.tsx';
import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import {
  getCompanyAggregators,
  getCompanyAllCollectorAvailableMeasurements,
  getCompanyAllCollectorParams,
  getCompanyCollectors,
  getCompanyFarms,
  selectNumberOfCollectors,
  selectNumberOfFarms,
} from '@store/reducers/collectorReducer.ts';
import { getMe, selectMe } from '@store/reducers/meReducer.ts';
import { meCheckIfLoggedIn } from '@store/reducers/reducerHelpers/meCheckIfLoggedIn.ts';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
// import CombinedCollectorGraph from '@components/CollectorGraphMeasurements/CombinedCollectorGraphMeasurements';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const me = useAppSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useAsyncEffect(async () => {
    const resultAction = await dispatch(meCheckIfLoggedIn());
    const result = await checkIfActionFulfilled(resultAction, meCheckIfLoggedIn);
    if (!result) {
      navigate(AppRoutes.signIn.path);
    }
  });

  useEffect(() => {
    if (me) {
      dispatch(getCompanyFarms());
      dispatch(getCompanyAggregators());
      dispatch(getCompanyCollectors());
      dispatch(getCompanyAllCollectorParams());
      dispatch(getCompanyAllCollectorAvailableMeasurements());
    }
  }, [me, dispatch]);

  const numberOfFarms = useAppSelector(selectNumberOfFarms);
  // const numberOfCollectors = useAppSelector((state) => selectNumberOfCollectorsForFarm(state, companyFarmUuid));
  const numberOfCollectors = useAppSelector(selectNumberOfCollectors);

  return (
    <div className="container mx-auto">
      {/* <h1 className="mb-4 text-2xl font-bold">Dashboard</h1> */}
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className="info_panel p-4 sm:ml-64">
        <div className="rounded-lg border-2 border-dashed border-gray-200 p-4 dark:border-gray-700">
          <div className="items-left justify-lef mb-4 flex rounded bg-gray-50 px-3 py-3 dark:bg-gray-800">
            <p className="text-2xl text-tlBlue dark:text-gray-500">Company Level Overview</p>
          </div>
          <div className="mb-4 grid items-center">
            <div className="grid grid-cols-4 grid-rows-1 gap-4">
              <div className="flex items-center">
                <img src={solarFarmIcon} alt="Solar Farm" style={{ width: '100px' }} />
                <div>
                  <h5 className="tl-large-heading">Sites</h5>
                  <div> Farms: {numberOfFarms}</div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={unitsIcon} alt="Tracker Unit" style={{ width: '38px', marginRight: '20px' }} />
                <div>
                  <h5 className="tl-large-heading">Units</h5>
                  <div> Collectors: {numberOfCollectors}</div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={batteriesIcon} alt="Tracker Unit" style={{ width: '34px', marginRight: '20px' }} />
                <div>
                  <h5 className="tl-large-heading">Capacity</h5>
                  <div> Demo: X kph</div>
                </div>
              </div>
              <div className="flex items-center">
                <img src={sunIcon} alt="Tracker Unit" style={{ width: '34px', marginRight: '20px' }} />
                <div>
                  <h5 className="tl-large-heading">Inverters</h5>
                  <div> % / %</div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4">
            <div className="justify-left flex h-full w-full flex-col items-center rounded bg-gray-50 dark:bg-gray-800">
              <div style={{ width: '100%', height: '100%' }}>
                <DemoCombinedCollectorGraph />
              </div>
              <div className="tl-medium-heading pb-2 text-2xl text-gray-400 dark:text-gray-500">
                Generation Output Analysis Demo Only
              </div>
              {/* <CombinedCollectorGraph farmUuid={companyFarmUuid} /> */}
            </div>

            <div className="justify-left flex h-full w-full flex-col items-center rounded bg-gray-50 dark:bg-gray-800">
              <div style={{ width: '100%', height: '100%' }}>
                <DemoCombinedCollectorGraphPerformance />
              </div>
              <div className="tl-medium-heading pb-2 text-2xl text-gray-400 dark:text-gray-500">
                Generation Performance Analysis Demo Only
              </div>
              {/* <CombinedCollectorGraph farmUuid={companyFarmUuid} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
