import Dashboard from '@pages/Dashboard.tsx';
import TCUAnalytics from '@pages/TCUAnalytics.tsx';
import React, { lazy } from 'react';

const NotFound404 = lazy(() => import('@pages/NotFound404.tsx'));
const LandingPage = lazy(() => import('@pages/LandingPage.tsx'));
const SignIn = lazy(() => import('@pages/authentication/SignIn.tsx'));
const Register = lazy(() => import('@pages/authentication/Register.tsx'));

export interface InterfaceAppRoute {
  path: string;
  element: React.JSX.Element;
  layout: string;
}

export interface InterfaceAppRoutes {
  [routeName: string]: InterfaceAppRoute;
}

const AppRoutes: InterfaceAppRoutes = {
  home: {
    path: '/',
    element: <LandingPage />,
    layout: 'blank',
  },
  dashboard: {
    path: '/dashboard',
    element: <Dashboard />,
    layout: 'default',
  },
  tcuAnalytics: {
    path: '/tcu-analytics',
    element: <TCUAnalytics />,
    layout: 'default',
  },
  landingPage: {
    path: '/LandingPage',
    element: <LandingPage />,
    layout: 'blank',
  },
  signIn: {
    path: '/signIn',
    element: <SignIn />,
    layout: 'blank',
  },
  register: {
    path: '/register',
    element: <Register />,
    layout: 'blank',
  },
  notFound404: {
    path: '*',
    element: <NotFound404 />,
    layout: 'blank',
  },
};

export { AppRoutes };
