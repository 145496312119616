import { InterfaceDataMeasurementType } from '@interfaces/InterfaceDataMeasurementType.ts';
import { InterfaceDataParameterType } from '@interfaces/InterfaceDataParameterType.ts';
import { InterfaceDataUnitType } from '@interfaces/InterfaceDataUnitType.ts';
import { InterfaceGenericMap } from '@interfaces/InterfaceGenericMap.ts';
import { InterfaceGenericTypeSlug } from '@interfaces/InterfaceGenericTypeSlug.ts';
import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '@store/appStore';
import { meLogOut } from '@store/reducers/meReducer.ts';
import createDebouncedAsyncThunk from '@store/reducers/reducerHelpers/createDebouncedAsyncThunk.ts';
import { sliceStateCheckAndUpdate } from '@store/reducers/reducerHelpers/sliceStateCheckAndUpdate.ts';
import thunkGet from '@store/reducers/reducerHelpers/thunkGet.ts';
import { defaultTypeDebounceTimeMs } from '@store/reducers/reducerHelpers/thunkHelperShared.ts';

export interface DataReducerState {
  dataTypes: InterfaceGenericMap<InterfaceGenericTypeSlug>;
  dataUnitTypes: InterfaceGenericMap<InterfaceDataUnitType>;
  dataParameterTypes: InterfaceGenericMap<InterfaceDataParameterType>;
  dataMeasurementTypes: InterfaceGenericMap<InterfaceDataMeasurementType>;
}

const initialState: DataReducerState = {
  dataTypes: {},
  dataUnitTypes: {},
  dataParameterTypes: {},
  dataMeasurementTypes: {},
};

export const sliceName = 'data';

export const dataSlice = createSlice({
  name: sliceName, // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Use `extraReducers` to handle actions that were generated
    // _outside_ of the slice, such as thunks or in other slices
    builder
      .addCase(getDataTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('dataTypes'))
      .addCase(getDataUnitTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('dataUnitTypes'))
      .addCase(getDataParameterTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('dataParameterTypes'))
      .addCase(getDataMeasurementTypes.fulfilled, sliceStateCheckAndUpdate.sliceItem('dataMeasurementTypes'))
      .addCase(meLogOut.fulfilled, () => initialState);
  },
});

export const getDataTypes = thunkGet.get(sliceName, `type/data`);
export const getDataUnitTypes = thunkGet.get(sliceName, `type/data/units`);
export const getDataParameterTypes = thunkGet.get(sliceName, `type/data/parameter`);
export const getDataMeasurementTypes = thunkGet.get(sliceName, `type/data/measurement`);

// export const { } = meSlice.actions;

export const selectDataState = (state: AppState) => state.data;
export const selectDataTypes = (state: AppState) => selectDataState(state).dataTypes;
export const selectDataUnitTTypes = (state: AppState) => selectDataState(state).dataUnitTypes;
export const selectDataParameterTypes = (state: AppState) => selectDataState(state).dataParameterTypes;
export const selectDataMeasurementTypes = (state: AppState) => selectDataState(state).dataMeasurementTypes;
export const selectDataMeasurementType = (measurementTypeSlug: string) => (state: AppState) =>
  selectDataMeasurementTypes(state)[measurementTypeSlug];

export default dataSlice.reducer;

export const loadInitialDataData = createDebouncedAsyncThunk(
  `${sliceName}/initial`,
  async (_, { dispatch }) => {
    dispatch(getDataTypes());
    dispatch(getDataUnitTypes());
    dispatch(getDataParameterTypes());
    dispatch(getDataMeasurementTypes());
  },
  defaultTypeDebounceTimeMs,
);
