import { Line } from 'react-chartjs-2';

const DemoCombinedCollectorGraphPerformance = () => {
  const data = {
    labels: Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`),
    datasets: [
      {
        label: 'All Sites Generation Capacity Demo',
        data: Array.from({ length: 24 }, (_, i) => 600 + 300 * Math.sin((2 * Math.PI * i) / 24)),
        backgroundColor: 'rgba(247, 148, 29,0.6)',
        borderColor: 'rgba(10, 34, 59,1)',
        borderWidth: 0.5,
        fill: false,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3,
    scales: {
      y: {
        title: {
          display: true,
          text: 'KWH',
          color: '#f7941d',
          font: {
            family: 'Helvetica',
            size: 12,
            lineHeight: 1.2,
          },
        },
        beginAtZero: true,
        ticks: {
          stepSize: 200,
          max: 1200,
        },
      },
    },
  };

  return (
    <div className="py-6" style={{ width: '100%', height: '100%' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default DemoCombinedCollectorGraphPerformance;
