import { Action, configureStore, createSelector, ThunkAction } from '@reduxjs/toolkit';
import collectorReducer from '@store/reducers/collectorReducer.ts';
import dataReducer, { loadInitialDataData } from '@store/reducers/dataReducer.ts';
import documentReducer, { loadInitialDocumentData } from '@store/reducers/documentReducer.ts';
import meReducer from '@store/reducers/meReducer';
import notificationReducer from '@store/reducers/notificationReducer.ts';
import routeReducer from '@store/reducers/routeReducer.ts';
import themeConfigReducer from '@store/reducers/themeConfigReducer.ts';
import userReducer from '@store/reducers/userReducer';
import isEqual from 'lodash-es/isEqual';

import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  useStore,
  type TypedUseSelectorHook,
} from 'react-redux';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';

import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from 'redux-persist/lib/storage';
import watch from 'redux-watch';

const collectorPersistConfig = {
  key: 'collector',
  version: 1,
  storage,
  blacklist: [''],
};

const dataPersistConfig = {
  key: 'data',
  version: 1,
  storage,
  blacklist: [''],
};

const documentPersistConfig = {
  key: 'document',
  version: 1,
  storage,
  blacklist: [''],
};

const mePersistConfig = {
  key: 'me',
  version: 1,
  storage,
  blacklist: ['search', 'loading', 'hasErrors', 'error', 'errors'],
};

const notificationPersistConfig = {
  key: 'notification',
  version: 1,
  storage,
  blacklist: [''],
};

const routePersistConfig = {
  key: 'route',
  version: 1,
  storage,
  blacklist: ['previousRoute', 'currentRoute'],
};

const themePersistConfig = {
  key: 'themeConfig',
  version: 1,
  storage,
  blacklist: [],
};

const userPersistConfig = {
  key: 'user',
  version: 1,
  storage,
  blacklist: ['search', 'loading', 'hasErrors'],
};

const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['collector', 'data', 'document', 'me', 'notification', 'route', 'themeConfig', 'user'],
};

const reducers = combineReducers({
  collector: persistReducer(collectorPersistConfig, collectorReducer),
  data: persistReducer(dataPersistConfig, dataReducer),
  document: persistReducer(documentPersistConfig, documentReducer),
  me: persistReducer(mePersistConfig, meReducer),
  notification: persistReducer(notificationPersistConfig, notificationReducer),
  route: persistReducer(routePersistConfig, routeReducer),
  themeConfig: persistReducer(themePersistConfig, themeConfigReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, reducers);

const appStore = configureStore({
  reducer: persistedReducer,
  // reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        immutableCheck: false, //TODO: Remove Later
        serializableCheck: false,
      },
    }),
  // .concat(logger),
  devTools: {
    maxAge: 50, // Limit the number of actions stored in the DevTools
    trace: false, // Disable stack trace logging
  },
});
export const appPersistStore = persistStore(appStore);

const w = watch(appStore.getState, 'me.user', isEqual);
appStore.subscribe(
  w(async (newVal: any) => {
    if (newVal === null) {
      console.log('Log out');
      await persistStore(appStore).purge();
    }
  }),
);

export type AppStore = typeof appStore;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch = () => useReduxDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useReduxSelector;

export const createAppSelector = createSelector.withTypes<AppState>();

export const useAppStore: () => AppStore = useStore;

// setupListeners(reduxStore.dispatch);

export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export default appStore;

appStore.dispatch(loadInitialDataData());
appStore.dispatch(loadInitialDocumentData());
