import axios from 'axios';
import React from 'react';

interface DownloadExcelButtonProps {
  baseUrl: string;
  companySlug: string;
  collectorUuid: string;
  dataMeasurementTypeSlug: string;
  fromTimestamp: string;
  toTimestamp: string;
}

const DownloadExcelButton: React.FC<DownloadExcelButtonProps> = ({
  baseUrl,
  companySlug,
  collectorUuid,
  dataMeasurementTypeSlug,
  fromTimestamp,
  toTimestamp,
}) => {
  const handleDownload = async () => {
    const url = `${baseUrl}/c/${companySlug}/admin/collector/${collectorUuid}/measurement/${dataMeasurementTypeSlug}/avg?from=${fromTimestamp}&to=${toTimestamp}&export_type=excel`;
    try {
      const response = await axios.get(url, {
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', `${dataMeasurementTypeSlug}-${fromTimestamp}-${toTimestamp}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the file', error);
    }
  };

  return (
    <button onClick={handleDownload} className="download-button graph-button">
      Download Excel
    </button>
  );
};

export default DownloadExcelButton;
