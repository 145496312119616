import { useAppDispatch, useAppSelector } from '@store/appStore.ts';
import { selectCompanyCollectorAvailableMeasurements } from '@store/reducers/collectorReducer.ts';
import { getDataMeasurementTypes, selectDataMeasurementTypes } from '@store/reducers/dataReducer.ts';
import { getOptionValueFromSlug, InterfaceOption } from '@utils/OptionsArrayHelpers.ts';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export type CollectorMeasurementSelectProps = {
  collectorUuid: string;
  setDataMeasurementTypeSlug: React.Dispatch<React.SetStateAction<string>>;
  initialValue: string;
  disabled?: boolean;
};

export default function CollectorMeasurementSelect({
  collectorUuid,
  setDataMeasurementTypeSlug,
  initialValue = '',
  disabled = false,
}: CollectorMeasurementSelectProps) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDataMeasurementTypes());
  }, [dispatch]);

  const dataMeasurementTypes = useAppSelector(selectDataMeasurementTypes);
  const companyCollectorAvailableMeasurements = useAppSelector(
    selectCompanyCollectorAvailableMeasurements(collectorUuid),
  );
  const excludedSlugs = ['tcu-error', 'tcu-mode', 'tcu-rtc-date'];

  const dataMeasurementTypesAvailable: InterfaceOption[] = Object.values(dataMeasurementTypes)
    .filter((dataMeasurementType) => {
      if (dataMeasurementTypes === undefined || companyCollectorAvailableMeasurements === undefined) return false;
      return (
        Object.keys(companyCollectorAvailableMeasurements).includes(dataMeasurementType.slug) &&
        !excludedSlugs.includes(dataMeasurementType.slug)
      );
    })
    .map((dataMeasurementType) => {
      return { value: String(dataMeasurementType.slug), label: dataMeasurementType.name };
    });

  const [dataMeasTypeSlug, setDataMeasTypeSlug] = useState<string>(initialValue);
  const dataMeasTypeValue = getOptionValueFromSlug(dataMeasurementTypesAvailable, dataMeasTypeSlug, false);
  return (
    <>
      <Select
        className="z-50"
        id="companyCollectorUuid"
        placeholder="Select Data Type"
        required={true}
        options={dataMeasurementTypesAvailable}
        value={dataMeasTypeValue}
        // isDisabled={disabled}
        noOptionsMessage={() => (disabled ? 'Please select Collector' : 'No options')}
        onChange={(option) => {
          const selectedValue = option?.value ?? '';
          setDataMeasTypeSlug(selectedValue);
          setDataMeasurementTypeSlug(selectedValue);
        }}
      />
    </>
  );
}
